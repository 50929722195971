import React from 'react'
import './Testimonials.css'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"


// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper/core';
import { AnimationOnScroll } from 'react-animation-on-scroll';

// install Swiper modules
SwiperCore.use([Pagination]);

export default function Testimonials() {
    return (
        <div className="container">
            <div className="row pt-t mb-5">
            <h3 className="d-flex justify-content-start title align-right ">Testimonials</h3>  
   
                <Swiper 
                    slidesPerView={3} 
                    spaceBetween={30} 
                    pagination={{"clickable": true}} 
                    breakpoints={{
                        "@0.00": {
                            "slidesPerView": 1,
                            "spaceBetween": 10
                        },
                        "@0.75": {
                            "slidesPerView": 1,
                            "spaceBetween": 20
                        },
                        "@1.00": {
                            "slidesPerView": 1,
                            "spaceBetween": 40
                        },
                        "@1.50": {
                            "slidesPerView": 1,
                            "spaceBetween": 50
                        }
                        }}
                    className="mySwiper">
                                                <AnimationOnScroll animateIn="animate__pulse">
                        <SwiperSlide style={{marginBottom:'1em'}}>
                            <div className="mt-3 mb-4">
                                <div className="card product">
                                <img className="image-cover"  src={window.location.origin + `/Assets/Image/Testimonials/kushal_archaya.jpg`} alt="." />
                                    <div className="card-body news-stories-heading">
                                    <p className="testimonial"> <b style={{fontSize:'25px'}}>“</b> It was wonderful working with the young and energetic team of Amakomaya. Covid Vaccine Equity Project (CVEP) supported Amakomaya for application development and piloting the system in the Tokha municipality.  We look forward to partnering in future projects.<b style={{fontSize:'25px'}}>"</b></p>
                                    <p className="name-testimonial"><b>Kushal Acharya</b> <br /> JSI R&T</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide style={{marginBottom:'1em'}}>
                            <div className="mt-3 mb-4">
                                <div className="card product">
                                <img className="image-cover"  src={window.location.origin + `/Assets/Image/Testimonials/basanta.jpg`} alt="." />
                                    <div className="card-body news-stories-heading">
                                    <p className="testimonial"> <b style={{fontSize:'25px'}}>“</b> Tokha Municipality is committed to fulfilling the digitalization campaign of the Government of Nepal in the public health sector. Tokha Municipality and Aamakomaya company agreed upon development and piloting of Covid vaccine online registration system. During the testing process, we got continuous support from the energetic and professional development team of the Aamakomaya company without which it would be almost impossible to design and develop such a scalable strong online registration system. I am looking forward to work with Aamakomaya in upcoming days to meet our health system digitalization campaign <b style={{fontSize:'25px'}}>"</b></p>
                                    <p className="name-testimonial"><b>Basanta Gaire </b> <br /> Health Co-ordinator, Tokha Municipality</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        </AnimationOnScroll>
                </Swiper> 
            </div>
        </div>
    )
}
