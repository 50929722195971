import React from 'react';
import "./Newsandstories.css";
import Newsandstoriescontent from "../NewsandStories/Newsandstoriescontent";
import Newsandstoriescontentdetail from '../NewsandStories/NewsandStoriedDetail/Newsandstoriescontentdetail'
import { Link } from "react-router-dom";
import Smbutton from '../../Components/Button/Smbutton';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function Newsandstories() {
  return (
    <>
    <div className="about-header">
      <div className="parallax relative" style={{ backgroundImage: "url(/Assets/Image/About/about-bg.jpg)", width: "100%"}}>
      </div>
      <h3 className="centered about aboutus-title">News and Stories</h3>
    </div>
     <div className="main mt-5">
        <ul className="cards">
          {Newsandstoriescontentdetail.map((data, index) => (
            <li className="cards_item">
              <div className="card">
              <AnimationOnScroll animateIn="animate__fadeIn">
                <div className="card_image"> 
                  <img
                    className="news-image card-img-left example-card-img-responsive"
                    src={`Assets/Image/impact/${data.image}`} alt="."
                  />
                </div>
                <div className="card_content">
                  <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center', }}>
                    <i className="fa fa-calendar"></i>
                    <p className="stories-date">{data.time}</p>
                  </div>
                  <h2 className="card_title custom-card-title">{data.title}</h2>
                  <p className="card_text custom-card-text">{data.description}</p>
                  <Link className="more" to={`/news-and-stories/${data.id}`}>
                    <Smbutton />
                  </Link>
                </div>
                </AnimationOnScroll>

              </div>
            </li>
          ))}

        {Newsandstoriescontent.map((item) => {
          return (
          <li key={item.id} className="cards_item">
            <div className="card">
            <AnimationOnScroll animateIn="animate__fadeIn">
              <div className="card_image"> 
                <img
                  className="news-image card-img-left example-card-img-responsive"
                  src={`Assets/Image/impact/${item.image}`} alt="."
                />
              </div>              
              <div className="card_content">
                <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center', }}>
                  <i style={{color: 'rgb(77 77 77)'}} className="fa fa-calendar"></i>
                  <p className="stories-date">{item.date}</p>
                </div>
                <h2 className="card_title custom-card-title">{item.title}</h2>
                <p className="card_text custom-card-text">{item.description}</p>
                <Link to={{ pathname: `${item.links}` }} target="_blank" className="btn-readmore align-center"><Smbutton /></Link>
              </div>
            </AnimationOnScroll>
            </div>
          </li>
            )
          })}
        </ul>
      
      </div>
    </>
  )
}
