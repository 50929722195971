import { data } from "jquery";
import React from "react";
import { useParams } from "react-router";
import './NewandstoriesDetail.css'
import Newsandstoriescontentdetail from './Newsandstoriescontentdetail'


export default function NewandstoriesDetail() {
  const {dataid} = useParams();
  console.log(dataid)
  
  return (
    <div className="container mb-5">
      {Newsandstoriescontentdetail
      .filter((card)=>card.id == dataid)
      .map((card, index)=>(
        <>
          <div className="title-detail">
            <h4>{card.detailtitle}</h4>
          </div>  
          <div className="title-description">
            <p>{card.detaildescription}</p>
          </div>
          <div className="row">
            <div className="title-image1 col-md-6">
              <img  width="100%" src={card.detailimageone} alt="" />
              <em>{card.captionone}</em>
            </div>
            <div className="title-image2 col-md-6">
              <img  width="100%" src={card.detailimagetwo} alt="" />
              <em>{card.captiontwo}</em>
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

