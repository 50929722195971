import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Aboutus.css";

export default function Aboutus() {
  return (
    <div>
      <div className="about-header">
        <div className="parallax relative" style={{ backgroundImage: "url(/Assets/Image/About/about-bg.jpg)", width: "100%"}}>
        </div>
        <h3 className="centered about aboutus-title">About us</h3>
      </div>
      <div className="container">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="row mt-5">
              <h4 style={{color: '#F78664'}}>What is amakomaya ?</h4>
              <p>Amakomaya is an easy and handy web-application designed and developed to support the pregnant women at the pregnancy period by providing the information about the change in her body during pregnancy period and safe pregnancy related information by the use of internet and mobile technology. By the use of internet, pregnant women can register to the application for the new account and by the use of username and password, pregnant women on her pregnancy period can get safe pregnancy information on audio, text and video content. Even these contents are supported on mobile. Amakomaya.com aims to inform and empower low-income, expectant mothers during her pregnancy and child delivery period as well as care during her pregnancy period and focuses the health of maternal, newborn and child health.</p>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="row mt-5">
            <h4 style={{color: '#F78664'}}>Impact of Maternal Mortality :</h4>
            <p>The pregnant women have to pay their life due to careless and lack of proper care information during pregnancy period. The lost of money and the possibility of chronic ill-health that can lead to lost opportunities to work and a poorer quality of life. Death of mother lead improper care of born baby and likely to die within two years and children without mothers are likely to receive adequate health care and educational opportunities as they grow up.
                The main objective of this project is to protect the life of pregnat women and new born baby by the use of internet and mobile technology where the people are far from city hospital.
            </p>
          
            <div className="impact-maternal">
              <img
                src={window.location.origin + `/Assets/Image/About/about-bg.jpg`}
                className="impact-maternal-img card-img-top img-reponsive img-fluid "
                alt="Logo"
              />
              <p style={{fontSize: '12px'}}>Info: "Pregnant Women watching Pregnancy Care Information in her mobile"</p>
              </div>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="row mt-5">
            <h4  style={{color: '#F78664'}}>Initiation of amakomaya.com :</h4>
            <p>“Pregnant Women and Infant Baby Protection (PWIBP) program” is one of the example of how simple ICT tool can provide information to remote women who never have chance to see hospital doctor during their pregnancy period. This program addresses the problem of accessing to pregnancy related health information for remote village over internet in PC and mobile. The program is started by the community grant of Internet Society (ISOC) Next Generation Leadership (NGL) program. Mr. Rajendra Prasad Poudel who have successfully completed his graduate in ISOC e-Learning program and awarded ISOC community grant US$ 4000.
              The program has developed online portal www.amakomaya.com which is focused to connect pregnant women, Female community health volunteers and city doctors to provide online as well as offline information for registered pregnant women during their pregnancy period. These all contents are all about how their baby is growing up and how her body is changing. Moreover, the content is useful for giving important instruction and tips for pregnant women.</p>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="row mt-5">
            <h4  style={{color: '#F78664'}}>How amakomaya.com works ?</h4>
            <p>Any women who are pregnant or are planning to get pregnant can register on this application www.amakomaya.com. Using her unique username and password she can login the system account.
              Once the pregnant women registered in portal she will start to get audio, video and text content in her profile in periodically manner. She will receive first content between 12 weeks of pregnancy. Then after till 28 weeks she will receive content in every 2 weeks. After 28 weeks the content is focused to family member of pregnant women. So it needs to listen together with family members over her mobile devices. Also there are 5 videos produced by Baby Center but narration has been customized in Nepali language.</p>
          </div>
          </AnimationOnScroll>

          <div className="row mt-5">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <h4 style={{color: '#F78664'}}>Use of Mobile by Pregnant Women:</h4>
            <p>If the pregnant women are busy with her daily work at home than she can download the audio and video content and save in her mobile according to her pregnancy week or can download all the audio and video contents at once from near health post where there is internet available or from cyber café , provided by the system and she can hear and see the content on her rest time or before bed on her mobile.
            </p>

            <div className="impact-maternal">
              <img
                className="impact-maternal-img"
                src={window.location.origin + `/Assets/Image/About/4.jpg`}
                className="impact-maternal-img card-img-top img-reponsive img-fluid "
                alt="Logo"
              />
              <p style={{fontSize: '12px'}}>Info: "Pregnant Women watching Pregnancy Care Information in her mobile"</p>
              </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeIn">
              <div className="row mt-5">
                <h4 style={{color: '#F78664'}}>Growing Community :</h4>
                <p>www.amakomaya.com is a part of growing community through the use of internet and mobile technology is to address health challenges of pregnant women from the low and middle income family and is committed to sharing pregnancy care information, expertise resources and best practices lesson learned to pregnant women.</p>
              </div>
              </AnimationOnScroll>
           
        </div>
        
      </div>
    </div>
  );
}