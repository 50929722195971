const Ourproductdetailcontent = [
    {
        id: 1,
        title: "",
        description: "",
        image: "contentapp.jpg",
        detailtitle: "Amakomaya",
        detaildescription: "Any women who are pregnant or are planning to get pregnant can register on this application by downloading Amakomaya app. Using her Phone number as username and password she can login the system account. Once the pregnant women registered in portal she will start to get audio, video and text content in her profile in periodically manner. She will receive first content between 12 weeks of pregnancy. Then after till 28 weeks she will receive content in every 2 weeks. After 28 weeks the content is focused to family member of pregnant women. Also there are 5 videos produced by Baby Center but narration has been customized in Nepali language.",
        captionone: "",
        captiontwo: "",
        detailimageone: "",
        detailimagetwo: "",
    },
    {
        id: 2,
        title: "",
        description: "",
        image: "vialtochild.jpg",
        detailtitle: "Vial to Child",
        detaildescription: "Vial to child is an electronic system developed by Amakomaya for vaccine recording and management. It is an android based application that uses mobile devices like tablet and cellular phones to record the immunization data at the point of service. It encorporates the modern day technology to immunization to give quicker and more reliable database. Vial to Child has been implemented in 25 healthpost of Nawalparasi district and 27 health post in Dadeldhura district. Vial to Child is used at the point of service by the health workers to register the immunization records of the children and also record the vaccine details. The digital records can easily be accessed by health workers and identifying the records of a child is even quicker unlike the traditional means i.e. hand written registers. Less human effort and minimum time consume to register baby. 19972 children have been registered till date in the system whose digital records can be viewed in a web based server.",
        captionone: "",
        captiontwo: "",
        detailimageone: "",
        detailimagetwo: "",
    },
    {
        id: 3,
        title: "",
        description: "",
        image: "amakomayacare.jpg",
        detailtitle: "Amakomaya Care",
        detaildescription: "Once the pregnant or are planning to get pregnant women registered in Amakomaya apps, then registered women will show in Amakomaya web and then Amakomaya team send registered women to midwife by province wise. Midwife use Amakomaya care app to connect and follow up registered women by province wise where Midwife will call registered women weather the women health condition and fetus is good or not. Registered women can call anytime in Toll-Free number. Total 7 midwife are there to care pregnant women by apps and phone calls.",
        captionone: "",
        captiontwo: "",
        detailimageone: "",
        detailimagetwo: "",
    },
    {
        id: 4,
        title: "",
        description: "",
        image: "imu.jpg",
        detailtitle: "IMU Nepal",
        detaildescription: "IMU (Information Management Unit) is designed to provide right information to right person in right time. MoHP (Ministry of Health and Population) formed (IMU). Aaama Ko Maya is IT partner of MOHP which is a private non-profit organization to develops and implements IMU system to facilitate data collection processes as a digital data collection system from point of data generation is initiated in a phase-wise manner for different components of COVID-19 related data – laboratory data, clinical profile data, and case investigation and contact tracing data along with collect data of covid vaccine from all assigned vaccine sites. Purpose of IMU: An Information Management Unit has been established at the MoHP under the ICS/MoHP, which aims to strengthen and operationalize COVID-19 related information management system for better-informed decisions and monitoring of the response activities. The Information Management Unit will assist the ICS operations, other ICS teams and entities of the MoHP by providing analysed information on a regular basis to help them in informed decision making to control the pandemic.",
        captionone: "",
        captiontwo: "",
        detailimageone: "",
        detailimagetwo: "",
    },
    
    
]

export default Ourproductdetailcontent;