import React, { useState } from 'react'
import {useSpring, animated, useTrail, a} from 'react-spring'
import './Landingpage.css' 



const Trail = ({ open, children }) => {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
      config: { mass: 1, tension: 800, friction: 300 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      height: open ? 110 : 0,
      from: { opacity: 0, x: 20, height: 0 },
    })
    
    return (
      <div>
        {trail.map(({ height, ...style }, index) => (
          <a.div key={index} className="trailsText" style={style}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    )
  }

export default function Landingpage() {

    const transition = useSpring({      
      from: { opacity: 0, transform: 'translate3d(-40%,0,0)' },
      to: { opacity: 1, transform: 'translate3d(0,0,0)' },
      config: {mass:1, tension:350, friction:100}
    })
    const [open, set] = useState(true)


    return (
        <animated.div className="landing-page" >
            <div className="container-fluid">
                <div className="row landing-contain">
                    <animated.div style={transition} className="landing-image col-md-7 align-self-center">
                        <img
                            src={`Assets/Image/LandingPage/landing_page.png`}
                            // src={window.location.origin + `/Assets/Image/LandingPage/landing_page.png`}
                            className="card-img-top img-fluid"
                            alt="Logo"
                        />
                    </animated.div>
                    <div className="quote col-md-5 align-self-center">
                        <Trail open={open}>
                            <p>DIGITAL</p>
                            <p>TRANSFORMATION</p>
                            <p>AT YOUR FINGERTIPS </p>
                        </Trail>

                    <button id="gooey-button">
                            Annual Report
                        </button>
                    </div>

                </div>
            </div>
        </animated.div>
    )
}
