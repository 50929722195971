const OurteamManagementMemberData = [
    {
        id: 1,
        name: 'Ms. Ambika Timila',
        designation: 'Chief Financial Officer, Yagiten Pvt. Ltd',
        image: "ambika_timila.jpg"
    },
    {
        id: 2,
        name: 'Ms. Bimala Adhikari',
        designation: 'Office Manage',
        image: "image_not_found.jpg"
    },
    {
        id: 3,
        name: 'Er. Durga Prasad Poudel',
        designation: 'Project Manager, Software Engineer, Android & Web Developer',
        image: "durga_poudel.jpg"
    },
    {
        id: 4,
        name: 'Ram Chandra Poudel',
        designation: 'Project Manager, Android & Web Developer',
        image: "ramchandrapoudel.jpg"
    },    
    
    {
        id: 5,
        name: 'Mr. Chatur Gurung',
        designation: 'Designer & Web Developer',
        image: "chatur_gurung.jpg"
    },

    {
        id: 6,
        name: 'Ashish Shrestha',
        designation: 'Android / Flutter Developer',
        image: "aashis_shrestha.jpg"
    },
    {
        id: 7,
        name: 'Anil Kharel',
        designation: 'Frontend Developer/ Designer',
        image: "anil_kharel.jpg"
    },
    {
        id: 8,
        name: 'Pratima Khakural',
        designation: 'Admin Officer/ Data Manager',
        image: "pratima_khakural.jpg"
    },
    {
        id: 9,
        name: 'Rejish Baidar',
        designation: 'ICT Trainer',
        image: "rejish_baidar.jpg"
    },
    {
        id: 10,
        name: 'Aayush joshi',
        designation: 'Designer',
        image: "aayush_joshi.jpg"
    },
    {
        id: 11,
        name: 'Sandip Gautam',
        designation: 'Software Documentation & QA Tester',
        image: "sandip_gautam.png"
    },
    {
        id: 12,
        name: 'Smriti Shrestha',
        designation: 'Registered Nurse/ Midwife',
        image: "smriti_shrestha.jpg"
    }
]

export default OurteamManagementMemberData;
