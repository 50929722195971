const OurteamVolunteerMemberData = [
    {
        id: 1,
        name: 'Mr. Amit Batajoo',
        designation: 'Software Engineer (Japan)',
        image: "image_not_found.jpg"
    },
    {
        id: 2,
        name: 'Mr. Aman Shrestha',
        designation: 'Research officer',
        image: "image_not_found.jpg"
    }

]

export default OurteamVolunteerMemberData;