import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import $ from 'jquery'
import Navbar from '../Components/Navbar/Navbar'
import {
  Aboutus,
  Contactus,
  Home,
  NewsandStories,
  Ourservice,
  Ourteam,
  Datapresentation,
} from '../Pages/index'
import Footer from '../Components/Footer/Footer'
import 'font-awesome/css/font-awesome.min.css'
import NewandstoriesDetail from '../Pages/NewsandStories/NewsandStoriedDetail/NewandstoriesDetail'
import Ourproductdetail from '../Pages/Home/Home-component/Ourproduct/OurproductDetail/Ourproductdetail'

const App = () => {
  return (
    <Router>
      <Navbar />
      <main>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/about" exact>
            <Aboutus />
          </Route>
          <Route path="/news-and-stories" exact>
            <NewsandStories />
          </Route>
          <Route path="/news-and-stories/:dataid">
            <NewandstoriesDetail />
          </Route>
          <Route path="/our-service" exact>
            <Ourservice />
          </Route>
          <Route path="/our-service/:dataid" exact>
            <Ourproductdetail />
          </Route>
          <Route path="/our-team" exact>
            <Ourteam />
          </Route>
          <Route path="/contact-us" exact>
            <Contactus />
          </Route>
          <Route path="/data" exact>
            <Datapresentation />
          </Route>
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </Router>
  )
}

export default App
