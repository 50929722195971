import React, {useState, useEffect} from 'react'
import Smbutton from '../../../../Components/Button/Smbutton';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './About.css';

export default function About() { 
    return (
        <div className="about-h pt-5">
            <div className="container">
                <div className="row">
                    <div className=" col-md-6">
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <div className="about-home-text about-heading">
                            <h3>About Us</h3>
                        </div>
                            <div className="about-description">
                                <p>Amakomaya is a digital tracking tool that promotes maternal health and safe child birth. It helps in tracking, planning and reporting the health conditions of a mother and the baby. With the help of colourful indicators in the Amakomaya app, Female Community Health Volunteer (FCHV) and health personnel can track the health condition of pregnant women and provide health care services accordingly. Amakomaya aims to inform and empower low-income, expectant mothers during her pregnancy and child delivery period as well as care during her pregnancy period and focuses on the health of maternal, newborn and child health.</p>
                                <Link className="about-readmore" to="/about">
                                    <Smbutton />
                                </Link>
            
                            </div>
                    </AnimationOnScroll>
                    </div>
                    <div className="about-home-img col-md-6">
                    <AnimationOnScroll animateIn="animate__fadeIn">          
                            <img
                            className=""
                                src={window.location.origin + `/Assets/Image/About/about-bg.jpg`}
                                className="card-img-top img-reponsive img-fluid "
                                alt="Logo"
                            />
                        </ AnimationOnScroll>
                    </div>
                </div>
            </div>
        </div>
    )
}
