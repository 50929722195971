import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './Ourteam.css';
import OurteamBoardMemberData from './OurteamBoardMemberData'
import OurteamManagementMemberData from './OurteamManagementMemberData'
import OurteamVolunteerMemberData from './OurteamVolunteerMemberData'

export default function Ourteam() {
    return (
        <div className="container">
            <div className="row">
                <h3 className="title mt-5 mb-5">Our Team</h3>
            </div>
                <h5 className="title-s">Board of Directors of Amakomaya (Mothers Love) Initiative :</h5>   
                <hr className="hr-color" />
                <div className="row mb-5">
                {OurteamBoardMemberData.map((item)=>{
                    return(
                      <div key={item.id} className="col-sm-4 mt-4">
                            <AnimationOnScroll animateIn="animate__fadeIn">
                            <div className="our-team">
                                <div className="pic">
                                {!item.linkwithimage ?  
                                <img 
                                 src={`Assets/Image/Ourteam/${item.image}`} alt="."/>
                                    :                                    
                                    <img width="200px !important"  src={item.linkwithimage}  alt="." />}
                                </div>
                                <h3 className="title-team">{item.name}</h3>
                                <span className="post">{item.designation}</span>
                                {/* <ul className="social">
                                    <li><a href="/#" className="fab fa-facebook" alt="." /></li>
                                    <li><a href="/#" className="fab fa-twitter" alt="." /></li>
                                    <li><a href="/#" className="fab fa-google-plus" alt="." /></li>
                                </ul> */}
                            </div>
                            </AnimationOnScroll>
                    </div>
                    )
                })}
                </div>
                <h5 className="title-s mt-5">Management & Developer teams of Amakomaya (Mothers Love) Initiative</h5>   
                <hr className="hr-color" />
                <div className="row mb-5">
                    {OurteamManagementMemberData.map((item)=>{
                        return(
                        <div key={item.id} className="col-sm-4 mt-4">
                            <AnimationOnScroll animateIn="animate__fadeIn">
                            <div className="our-team">
                                <div className="pic">
                                {!item.linkwithimage ?  <img 
                                src={`Assets/Image/Ourteam/${item.image}`} alt="."/>    
                                    :                                    
                                    <img width="200px !important"  src={item.linkwithimage}  alt="." />}
                                </div>
                                <h3 className="title-team">{item.name}</h3>
                                <span className="post">{item.designation}</span>
                                {/* <ul className="social">
                                    <li><a href="/#" className="fab fa-facebook" alt="." /></li>
                                    <li><a href="/#" className="fab fa-twitter" alt="." /></li>
                                    <li><a href="/#" className="fab fa-google-plus" alt="." /></li>
                                </ul> */}
                            </div>
                            </AnimationOnScroll>
                        </div>
                        )
                    })}  
                </div>
                <h5 className="title-s mt-5">International Volunteer and Interns in Amakomaya</h5>   
                <hr className="hr-color" />
                <div className="row mb-5">
                {OurteamVolunteerMemberData.map((item)=>{
                    return(
                      <div key={item.id} className="col-sm-4">
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <div className="our-team">
                                <div className="pic">
                                {!item.linkwithimage ?                             
                                    <img src={`Assets/Image/Ourteam/${item.image}`} alt="."/>  
                                    :                                    
                                    <img width="200px !important"  src={item.linkwithimage}  alt="." />}
                                </div>
                                <h3 className="title-team">{item.name}</h3>
                                <span className="post">{item.designation}</span>
                                {/* <ul className="social">
                                    <li><a href="/#" className="fab fa-facebook" alt="." /></li>
                                    <li><a href="/#" className="fab fa-twitter" alt="." /></li>
                                    <li><a href="/#" className="fab fa-google-plus" alt="." /></li>
                                </ul> */}
                            </div>
                        </AnimationOnScroll>
                    </div>
                    )
                })}  
                </div>                       
            </div>
    )
}
