import React from 'react'
import './SystemData.css';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function SystemData() {
    return (
        <div className="systemdata">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                    <AnimationOnScroll delay={50}  animateIn="animate__pulse">
                        <div className="box box1">
                            <p className="box-numb">
                                <CountUp
                                    // suffix="K+"
                                    delay={0.3}
                                    start={0}
                                    end={5771}
                                    redraw={true}
                                >
                                {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </p>
                            <p className="box-text">Benifited Women</p>
                        </div>
                    </AnimationOnScroll>
                    </div>
                    <div className="col-md-4">
                    <AnimationOnScroll delay={100}  animateIn="animate__pulse">
                        <div className="box box2">
                            <p className="box-numb">
                                <CountUp
                                    // suffix="K+"
                                    delay={0.3}
                                    start={0}
                                    end={19561}
                                    redraw={true}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </p>
                            <p className="box-text">Benifited Child</p>
                        </div>
                    </AnimationOnScroll>
                    </div>
                    <div className="col-md-4">
                    <AnimationOnScroll delay={150}  animateIn="animate__pulse">
                        <div className="box box3">
                            <p className="box-numb">
                                <CountUp
                                    suffix="+"
                                    delay={0.3}
                                    start={0}
                                    end={23}
                                    redraw={true}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </p>
                            <p className="box-text">Local level</p>
                        </div>
                    </AnimationOnScroll>
                    </div>
                </div>
            </div>
        </div>
    )
}
