import React from 'react'
import './Footer.css'
import 'font-awesome/css/font-awesome.min.css';


export default function Footer() {
    return (
        <>
            <footer className="site-footer">
            <div className="container">
                <div className="row">
                <div className="col-xs-3 col-md-3">
                    <h6>Funding Organizations</h6>
                    <ul className="footer-links">
                    <li><a href="https://isif.asia/"> - ISIF Asia </a></li>
                    <li><a href="https://www.internetsociety.org//">- Internet Society</a></li>
                    <li><a href="http://www.yagiten.com/">- Yagiten Pvt. Ltd.</a></li>                  
                    </ul>
                </div>

                <div className="col-xs-3 col-md-3">
                    <h6>Videos Provider</h6>
                    <ul className="footer-links">
                    <li><a href="https://www.babycenter.com/">- Baby Center</a></li>
                    <li><a href="https://www.youtube.com/">- Youtube.com</a></li>
                    </ul>
                </div>

                <div className="col-xs-3 col-md-3">
                    <h6>Content Developer</h6>
                    <ul className="footer-links">
                    <li><a href="https://www.phectnepal.org/kathmandu-model-hospital-kmh/">- Model Hospital</a></li>
                    <li><a href="https://www.pahs.edu.np/">- Patan Academy of Health Sciences</a></li>
                    </ul>
                </div>
                <div className="col-xs-3 col-md-3">
                    <h6>Contact Us</h6>
                    <ul className="footer-links">
                        <p>Shivabhakta Marga, Kathmandu, Nepal. Tel : 01-4428090</p>
                    </ul>
                </div>
                </div>
                <hr/>
            </div>
            <div className="container">
                <div className="row">
                <div className="col-md-8 col-sm-6 col-xs-12">
                    <p className="copyright-text">Copyright &copy; 2021 Amakomaya.com. All Rights Reserved.</p>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12">
                    <ul className="social-icons">
                    <li><a className="facebook" href="https://www.facebook.com/amakomaya2012/"><i className="fa fa-facebook"></i></a></li>
                    <li><a className="twitter" href="https://twitter.com/amakomaya"><i className="fa fa-twitter"></i></a></li>
                    <li><a className="instagram" href="https://www.instagram.com/amakomaya/"><i className="fa fa-instagram"></i></a></li>

                    
                    </ul>
                </div>
                </div>
            </div>
            </footer>
        </>
    )
}
