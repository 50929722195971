const Newscontent = [
    {
        id: 1,
        title: "5 ways people bridge divides and build their #IRLDream",
        description: "Even in the Internet-positive circles we move in, some still dismiss the real life impact of things ..",
        image: "1.jpg",
        links: "5ways.jpg",
        date: "2020-2-10",
        detaildescription: "",
    },
    {
        id: 2,
        title: "E-health Strategy",
        description: "Amakomaya is one of the first m-health initiative in Nepal that has evolved overdate. The basic aim of the project is to increase the access of health information and knowledge to rural pregnant women, their family and the health workers too.",
        image: "7.jpg",
        date: "2020-2-10",
        detaildescription: "",
        links: "http://www.amakomaya.com/backend/web/uploads/image/Amakomaya_report_2014.pdf"
    },

    {
        id: 3,
        title: "10 Great Ways the Internet Is Empowering Women Around the World",
        description: "The Amakomaya Project (or Mother's Love) was started to ...",
        image: "2.jpg",
        date: "2020-2-10",
        detaildescription: "",
        links: 'http://www.huffingtonpost.com/kathy-brown/10-great-ways-the-interne_b_6817738.html'
    },
    {
        id: 4,
        title: "Interview with Malvika Subba during Pregnancy by Amakomaya com",
        description: "Interview with Malvika Subba during Pregnancy by Amakomaya com",
        image: "malvika.png",
        links: "https://www.youtube.com/watch?v=3dtONpNRekE",
        date: "2020-2-10",
        detaildescription: "",
    },
    {
        id: 5,
        title: "Women in Nepal receive prenatal care via mobile app",
        description: "In Nepal, it is difficult for expecting mothers to regularly visit their doctors for prenatal health check-ups...",
        image: "prenantcare.png",
        date: "2020-2-10",
        detaildescription: "",
        links: "https://www.readglobal.org/192-amakomaya/"
    },
    {
        id: 6,
        title: "Expectant mothers monitored through a mobile application",
        description: "In an era where mobile phones have become indispensable, ...",
        image: "Expectant.png",
        date: "2020-2-10",
        detaildescription: "",
        links: "https://kathmandupost.com/national/2014/07/21/expectant-mothers-monitored-through-a-mobile-application#.VNTtvvNpnC8.facebook"
    },   
]

export default Newscontent;