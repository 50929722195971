import React, { useEffect } from 'react'
import './Navbar.css'
import { NavLink, Link } from 'react-router-dom'
import $ from 'jquery'
import logo from '../../../src/image/Logo/logo.png';

const Navbar = () => {
  function animation() {
    var tabsNewAnim = $('#navbarSupportedContent')
    var activeItemNewAnim = tabsNewAnim.find('.active')
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight()
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth()
    var itemPosNewAnimTop = activeItemNewAnim.position()
    var itemPosNewAnimLeft = activeItemNewAnim.position()
    $('.hori-selector').css({
      top: itemPosNewAnimTop.top + 'px',
      left: itemPosNewAnimLeft.left + 'px',
      height: activeWidthNewAnimHeight + 'px',
      width: activeWidthNewAnimWidth + 'px',
    })
    $('#navbarSupportedContent').on('click', 'li', function (e) {
      $('#navbarSupportedContent ul li').removeClass('active')
      $(this).addClass('active')
      var activeWidthNewAnimHeight = $(this).innerHeight()
      var activeWidthNewAnimWidth = $(this).innerWidth()
      var itemPosNewAnimTop = $(this).position()
      var itemPosNewAnimLeft = $(this).position()
      $('.hori-selector').css({
        top: itemPosNewAnimTop.top + 'px',
        left: itemPosNewAnimLeft.left + 'px',
        height: activeWidthNewAnimHeight + 'px',
        width: activeWidthNewAnimWidth + 'px',
      })
    })
  }

  useEffect(() => {
    animation()
    $(window).on('resize', function () {
      setTimeout(function () {
        animation()
      }, 500)
    })
  }, [])

  return (
    <nav className="navbar navbar-expand-lg navbar-mainbg">
      <NavLink className="nav-link" to="/" exact>
        <div className="navbar-brand navbar-logo" to="/about">
          <img
          src={logo}
            // src={process.env.PUBLIC_URL + `/Assets/Image/Logo/logo.png`}
            className="card-img-top img-fluid company-logo"
            alt="Logo"
          />
        </div>
      </NavLink>

      <button
        className="navbar-toggler"
        onClick={function () {
          setTimeout(function () {
            animation()
          })
        }}
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="fas fa-bars text-white"></i>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <div className="hori-selector">
            <div className="left"></div>
            <div className="right"></div>
          </div>

          <li className="nav-item active">
            <NavLink className="nav-link" to="/" exact>
              Home
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink className="nav-link" to="/about" exact>
              About
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink className="nav-link" to="/news-and-stories" exact>
              News And Stories
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/our-service" exact>
              Our services
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink className="nav-link" to="/our-team" exact>
              Our Team
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contact-us" exact>
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  )
}
export default Navbar
