import React from 'react';
import './App.css';
// import {
//   BrowserRouter as Router,
//   Route,
//   Redirect,
//   Switch
// } from 'react-router-dom';
import "animate.css/animate.min.css";

import Chart from 'react-google-charts';

import Routes from './Routes/Routes'

const App = () => {
  return (
    <>
    <Routes />
    </>
  );
}

export default App;