import React from 'react'
import './Ourproduct.css'
import {SwiperSlide, Swiper} from 'swiper/react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"


// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper/core';
import Smbutton from '../../../../Components/Button/Smbutton';
import { Link } from 'react-router-dom';
import Ourproductdetail from './OurproductDetail/Ourproductdetail';
import Ourproductdetailcontent from './OurproductDetail/Ourproductdetailcontent';

// install Swiper modules
SwiperCore.use([Pagination]);
export default function Ourproduct() {
    return (
        <>
        <div className="ourservices-bg">
            <div className="container">
                <div className="row mt-5 mb-5">
                    <h3 className="title service-initiative mb-3"> Our Services & Initiative</h3>
                    <Swiper 
                    slidesPerView={3} 
                    spaceBetween={30} 
                    pagination={{"clickable": true}} 
                    breakpoints={{
                        "@0.00": {
                          "slidesPerView": 1,
                          "spaceBetween": 10
                        },
                        "@0.75": {
                          "slidesPerView": 2,
                          "spaceBetween": 20
                        },
                        "@1.00": {
                          "slidesPerView": 3,
                          "spaceBetween": 40
                        },

                        "@1.50": {
                          "slidesPerView": 3,
                          "spaceBetween": 50
                        }
                      }}
                    className="mySwiper">
                        <SwiperSlide style={{marginBottom:'1em'}}>
                            <div className="mt-3 mb-4">
                                <AnimationOnScroll delay={100}  animateIn="animate__zoomIn">
                                    <div className="card product">
                                        <img src={window.location.origin + `/Assets/image/ourproduct/contentapp.jpg`} alt="" />
                                        <div className="card-body news-stories-heading">
                                            <h5 className="card-title">Amakomaya</h5>
                                            <p className="card-text">Amakomaya is a digital tracking tool that promotes maternal health and safe child birth.</p>
                                            <Link className="more" to={`/our-service/1`}>
                                                <Smbutton />
                                            </Link>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="mt-3 mb-4">
                                <AnimationOnScroll delay={200}  animateIn="animate__zoomIn">
                                    <div className="card product">
                                        <img src={window.location.origin + `/Assets/image/ourproduct/vialtochild.jpg`} alt="" />
                                        <div className="card-body news-stories-heading">
                                            <h5 className="card-title">Vial to Child</h5>
                                            <p className="card-text">Vial to child is an electronic system developed by Amakomaya for vaccine recording and manangement. It is an android based application that uses mobile devices like tablet and cellular phones to record the immunization data at the point of service. </p>
                                            <Link className="more" to={`/our-service/2`}>
                                                <Smbutton />
                                            </Link>                                        
                                            </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="mt-3 mb-4">
                            <AnimationOnScroll delay={300}  animateIn="animate__zoomIn">

                                <div className="card product">
                                    <img src={window.location.origin + `/Assets/image/ourproduct/amakomayacare.jpg`} alt="" />
                                    <div className="card-body news-stories-heading">
                                        <h5 className="card-title">Amakomaya Care</h5>
                                        <p className="card-text">Amakomaya Care is an application used by FCHVs and health worker of health post to keep
                                            detail record of pregnant mother. FCHVs registers pregnant mothers in their android mobile
                                            by recording basic information (Name & caste, Last menstruation period date (LMP), age
                                            and ward number) and syncing recorded data in server through the use of internet. Every
                                            FCHVs have their own login account of Amakomaya women
                                        </p>
                                        <Link className="more" to={`/our-service/3`}>
                                            <Smbutton />
                                        </Link>    
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="mt-3 mb-4">
                                <AnimationOnScroll delay={400}  animateIn="animate__zoomIn">
    
                                    <div className="card product">
                                        <img src={window.location.origin + `/Assets/image/ourproduct/imu.jpg`} alt="" />
                                        <div className="card-body news-stories-heading">
                                            <h5 className="card-title">IMU Nepal</h5>
                                            <p className="card-text">IMU Nepal is the app for the tracking the COVID-19 patient.</p>
                                            <Link className="more" to={`/our-service/4`}>
                                                <Smbutton />
                                            </Link>    
                                        </div>
                                    </div>
                                    </AnimationOnScroll>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </>
    )
}
