const OurteamBoardMemberData = [
    {
        id: 1,
        name: 'Rajendra Poudel',
        designation: 'Founder, Chairperson of the Board and Executive Director',
        image: "rajendra_poudel.jpg"
    },

    {
        id: 2,
        name: 'Mahabir Pun',
        designation: 'Founder, Chairperson of the Board and Executive Director',
        image: "mahabir_pun.jpg",
    },
    {
        id: 3,
        name: 'Saroj Dhital',
        designation: 'Director, Vice-chairperson, Asst. Professor Patan Academy and Health Science',
        image: "Saroj_Dhital.jpg"
    },

    {
        id: 4,
        name: 'Ram Prasad Sharma',
        designation: 'Member, Community Health Nurse',
        image: "Ram_Prasad_Sharma.jpg"
    },

    {
        id: 5,
        name: 'Ryoichi Kuwayama',
        designation: 'Member, Community Health Nurse',
        image: "Ryoichi_Kuwayama.jpg"
    },
    

]

export default OurteamBoardMemberData;