import React, { useRef, useState } from 'react'
import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/lib/styles.css'
import 'react-owl-carousel2/src/owl.theme.default.css'
import PartnersLogoData from './PartnersLogoData'
import './Partners.css'

const options = {
  items: 6,
  margin: 5,
  responsiveclass: true,
  autoplay: true,
  loop: true,
  autoplayTimeout: 1500,
  autoplaySpeed: 1000,
  // autoplayHoverPause: true,
  slideTransition: 'linear',
  responsive: {
    0: {
      items: 4,
    },
    400: {
      items: 4,
    },
    600: {
      items: 4,
    },
    700: {
      items: 5,
    },
    1000: {
      items: 5,
    },
  },
}
export default function Partners() {
  return (
    <div className="partners-bg">
      <OwlCarousel className="slider-items owl-carousel" options={options}>
        {PartnersLogoData.map((data) => (
          <div key={data.id} className="item">
            <img
              className="news-image card-img-left example-card-img-responsive"
              src={
                window.location.origin + `/Assets/Image/PartnersLogo/${data.image}`
              }
              alt={data.alt}
            />
          </div>
        ))}
      </OwlCarousel>
    </div>
  )
}
