import React from 'react'
import {LandingPage, About, SystemData, Ourservice, Distinctfeature, Datapresentation, Testimonials, Partners} from './Home-component/Index'

export default function Home() {
    return (
        <div className="home-component">
            <LandingPage />
            <About />
            <SystemData />
            <Ourservice />
            <Distinctfeature />
            <Datapresentation />
            <Testimonials /> 
            <Partners />
        </div>
    )
}