import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './Distinctfeature.css'
import DistinctfeatureData from './DistinctFeatureData'

export default function Distinctfeature() {
    return (
        <div className="distinctfeature-bg">
            <div className="container">
                <div className="row">
                    <h3 className="title mt-5 mb-5">Distinct Feature</h3>
                </div>
                <div className="feature">
                    <div className="row">
                        {DistinctfeatureData.map((item) => {
                            return (
                                <div key={item.id} className="col-md-4 mb-5">
                                    <AnimationOnScroll animateIn="animate__pulse">
                                        <div className="card distinct-card">
                                            <img
                                                width="100"
                                                height="100"
                                                src={
                                                    window.location.origin +
                                                    `/Assets/Image/DistinctFeature/${item.image}`
                                                }
                                                alt=""
                                                className="mb-5 align-self-center"
                                            />
                                            <h5>
                                                <b>{item.title}</b>
                                            </h5>
                                            <p>{item.description}</p>
                                        </div>
                                    </AnimationOnScroll>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}