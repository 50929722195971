import React from 'react'
import Chart from "react-google-charts";
import DistrictMap from './DistrictMap';
import './Datapresentation.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function Datapresentation() {
return (
<div className="datapresentation-bg">
   <div className="container mb-5">
      <div className="row">
         <h3 className="title mt-5 mb-5">Statistical Data</h3>
      </div>
      <div className="row">
         <div className="col-md-7">
         <AnimationOnScroll animateIn="animate__fadeIn">          
            <Chart
            className="chart-data"
            height={'300px'}
            chartType="Bar"
            loader={
            <div>Loading Chart</div>
            }
            data={[
            ['Year', 'Registered'],
            ['Province 1', 381],
            ['Province 2', 84],
            ['Bagmati', 953],
            ['Gandaki', 378],
            ['Lumbini', 303],
            ['Karnali', 56],
            ['Sudurpaschim', 129],
            ]}
            options={{
            chart: {
            title: 'Number of Registered Women in Aamakomaya Content',
            subtitle: 'Number of Self-registered women 2020-2021',
            animation: {
               startup: true,
               easing: 'linear',
               duration: 1500,
             }
            },
            colors: ['#227893', '#B61B1B', '#822659', '#f3b49f', '#f6c7b6']
            }}
            />
            </AnimationOnScroll>
         </div>
         <div className="col-md-5">
         <AnimationOnScroll animateIn="animate__fadeIn">          

            <Chart
            height={'300px'}
            chartType="PieChart"
            loader={
            <div>Loading Chart</div>
            }
            data={[
            ['Task', 'Hours per Day'],
            ['Less than 20',133],
            ['Between 20-30', 1873],
            ['More than 30', 420]
            ]}
            options={{
            title: 'Age Group',
            pieHole: 0.4,
            animation: {
               startup: true,
               easing: 'linear',
               duration: 1500,
             },
            }}
            rootProps={{ 'data-testid': '3' }}
            />
            </AnimationOnScroll>
         </div>
      </div>
      <div className="row">
         <div className="col-md-12">
         <AnimationOnScroll animateIn="animate__fadeIn">          

            <Chart
            height={'300px'}
            chartType="AreaChart"
            
            loader={
            <div>Loading Chart</div>
            }
            data={[
            ['Year', 'Women', 'Baby'],
            ['2012', 1000, 400],
            ['2013', 1000, 400],
            ['2014', 1170, 460],
            ['2015', 660, 1120],
            ['2016', 2030, 1000],
            ['2017', 3030, 1540],
            ['2018', 4030, 2000],
            ['2019', 6030, 3540],
            ['2020', 5030, 2000],
            ['2021', 9030, 3500],
            ]}
            options={{
            title: 'Women and Baby Registered',
            hAxis: { title: 'Year', titleTextStyle: { color: '#333' } },
            vAxis: { minValue: 0 },
            chartArea: { width: '65%', height: '70%' },
            animation: {
               startup: true,
               easing: 'linear',
               duration: 1500,
             },
            }}
            />
            </AnimationOnScroll>
         </div>
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn">          

      <div>
         <h5 className="map-title">Registered women in Amakomaya App</h5>
      </div>
      <div>
         <img
            className="news-image card-img-left example-card-img-responsive"
            src={
               window.location.origin + `/Assets/Image/Datarepresentation/nepal-map.png`
            }
            alt="."
         />
      </div>
      </AnimationOnScroll>
      {/* <DistrictMap /> */}
   </div>
</div>
)
}
