import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { Link } from 'react-router-dom';
import Smbutton from '../../Components/Button/Smbutton'
import Ourproductdetailcontent from '../Home/Home-component/Ourproduct/OurproductDetail/Ourproductdetailcontent'

export default function Ourservice() {
    return (
        <div>
             <>
                <div className="ourservices-bg">
                    <div className="container">
                    <div className="row">
                        <h3 className="title mt-5 mb-5">Our Services & Initiative</h3>
                    </div>              
                    <AnimationOnScroll animateIn="animate__fadeIn">          
                    <div className="row mb-5">      
                    {Ourproductdetailcontent.map(({id, detailtitle, detaildescription, image}) => (
                        <div className="col-md-4 mt-3 mb-4">
                            <div className="card product">
                                <img 
                                src={`Assets/Image/ourproduct/${image}`}
                                // src={window.location.origin + `/Assets/Image/ourproduct/${image}`} 
                                alt="" />
                                <div className="card-body news-stories-heading">
                                    <h5 className="card-title">{detailtitle}</h5>
                                    <p className="card-text">{detaildescription}</p>
                                    <Link className="more" to={`/our-service/${id}`}>
                                    <Smbutton />
                                    </ Link>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                        </AnimationOnScroll>
                    </div>
                </div>
            </>
        </div>
    )
}
