const PartnersLogoData = [
  {
    id: 1,
    image: 'fondation.jpg',
    alt: 'fondation'
},
  {
    id: 2,
    image: 'internetsoceity.png',
    alt: 'internetsoceity'
},
  {
    id: 3,
    image: 'isif.png',
    alt: 'isif'
},
  {
    id: 4,
    image: 'mohp.png',
    alt: 'mohp'
},
  {
    id: 5,
    image: 'pahs.png',
    alt: 'pahs'
},
  {
    id: 6,
    image: 'UNICEF-Logo.png',
    alt: 'UNICEF'
},
  {
    id: 7,
    image: 'World-Health-Organization-WHO-Logo.png',
    alt: 'WHO'
},
]

export default PartnersLogoData
